import React from "react"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faHome} from '@fortawesome/free-solid-svg-icons'

import "../../styles/site.scss"
import "./profili.scss"
import {Link} from "gatsby";
import ProfiliGraditeljstvo from "./profili_graditeljstvo";
import ProfiliPoljoprivreda from "./profili_poljoprivreda";
import ProfiliKontejneri from "./profili_kontejneri";
import ProfiliOgrade from "./profili_ograde";
// import "../styles/debug.scss"
// import "../styles/helpers.scss"
// import "../styles/grid.scss"

const ProfiliPage = () => {
    return (
        <>
            <section className="hero profili">
                <div className="hero-body">
                    <div className="container">
                        <h1 className="title is-1">Usluge savijanja</h1>
                        <h2 className="subtitle">Gestus Metal</h2>
                    </div>
                </div>
            </section>

            <section className="section profili">
                <div className="container">
                    <nav className="breadcrumb has-succeeds-separator" aria-label="breadcrumbs">
                        <ul>
                            <li>
                                <Link to="/">
                                    <FontAwesomeIcon icon={faHome}/>&ensp;
                                    Naslovnica
                                </Link>
                            </li>
                            <li className="is-active"><a href="#" aria-current="page">Usluge savijanja</a></li>
                        </ul>
                    </nav>
                </div>

                <ProfiliGraditeljstvo/>
                <ProfiliPoljoprivreda/>
                <ProfiliKontejneri/>
                <ProfiliKontejneri/>
                <ProfiliOgrade/>
            </section>
        </>
    )
}

export default ProfiliPage
