import React from "react";
import ProfilCard from "../../components/profil-card";

const ProfiliGraditeljstvo = () => {
    return (
        <>
            <div className="container mt-5">
                <h1 className="title is-3">Profili za graditeljstvo</h1>
                <hr/>
            </div>

            <div className="container">
                <ProfilCard title="'U' profil" lPic="profil-u-s.jpg" rPic="profil-u.jpg"/>
                <ProfilCard title="'Z' profil" lPic="profil-z-s.jpg" rPic="profil-z.jpg"/>
                <ProfilCard title="'C' profil" lPic="profil-c-s.jpg" rPic="profil-c.jpg"/>
                <ProfilCard title="Omega profil" lPic="profil-omega-s.jpg" rPic="profil-omega.jpg"/>
                <ProfilCard title="Sigma profil" lPic="profil-sigma-s.jpg" rPic="profil-sigma.jpg"/>
            </div>
        </>
    )
}

export default ProfiliGraditeljstvo
